import axios from 'axios';

const jwt = localStorage.getItem("token");

// const baselocal = 'http://localhost:5000/api';
const baseprod = "https://go.groceryguru.app/api";

  const axiosInstance = axios.create({
  baseURL: baseprod,
  headers: {
    Authorization: `Bearer ${jwt}`, // Add your authorization token
  },
});



export default axiosInstance;
