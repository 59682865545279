import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from './context/UserContext';  // Import UserContext
import TopBar from './TopBar';  


const ProtectedRoutes = () => {
    const user = localStorage.getItem("user")

    // Only check for user once loading is complete
    if (!user) {
        console.log('No user found, redirecting to /login');
        return <Navigate to="/login" />;
    }

    // If user is authenticated, render the protected content
    return (
        <>
            <TopBar />  {/* TopBar will appear on all protected routes */}
            <Outlet />  {/* Outlet renders the nested/protected components */}
        </>
    );
};

export default ProtectedRoutes;
