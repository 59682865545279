import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ShoppingContext } from './context/ShoppingContext';
import {
  Box,
  Typography,
  CircularProgress,
  Tabs,
  Tab,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
} from '@mui/material';
import axiosInstance from './BasePath/axiosInstance';

const FlyerItems = () => {
  const { merchantId } = useParams();
  const [items, setItems] = useState([]);
  const [merchant, setMerchant] = useState({ name: '', logoUrl: '' });
  const [validity, setValidity] = useState({ validFrom: null, validTo: null });
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('All'); // State for selected category

  const { shoppingList, fetchShoppingList, addItemToShoppingList, removeItemFromShoppingList } =
    useContext(ShoppingContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch flyer items
        const response = await axiosInstance.get(`/flyers/items/${merchantId}`);
        const data = response.data;

        const enhancedItems = data.map((item) => ({
          ...item,
          isInShoppingList: Array.isArray(shoppingList) && shoppingList.some((listItem) => listItem?._id === item?._id),
        }));

        setItems(enhancedItems || []);
        if (enhancedItems.length > 0) {
          setValidity({ validFrom: enhancedItems[0].validFrom, validTo: enhancedItems[0].validTo });
        }

        // Fetch merchant details
        const merchantResponse = await axiosInstance.get(`/api/flyers/merchants/${merchantId}`);
        const merchantData = merchantResponse.data;
        setMerchant({ name: merchantData.merchantName, logoUrl: merchantData.logoUrl });
      } catch (error) {
        console.error('Error fetching flyer data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [merchantId, shoppingList]);

  const groupedItems = (Array.isArray(items) ? items : []).reduce((groups, item) => {
    const category = item.mainCategoryEn || 'Uncategorized';
    if (!groups[category]) groups[category] = [];
    groups[category].push(item);
    return groups;
  }, {});

  const displayedItems = selectedCategory === 'All' ? items : groupedItems[selectedCategory] || [];

  const renderPriceInfo = (item) => {
    let priceInfo = '';
    if (item.itemPrePriceText) priceInfo += `${item.itemPrePriceText} `;
    if (item.itemPrice !== null) priceInfo += `$${item.itemPrice}`;
    if (item.itemDiscount > 0) priceInfo += ` (${item.itemDiscount}% off)`;
    if (item.itemPostPriceText) priceInfo += ` ${item.itemPostPriceText}`;
    if (item.itemSaleStory) priceInfo += ` - ${item.itemSaleStory}`;
    return priceInfo;
  };

  const handleAddItem = async (item) => {
    try {
      await addItemToShoppingList(item.merchantName, item);
      await fetchShoppingList();
    } catch (error) {
      console.error('Error adding item to shopping list:', error);
    }
  };

  const handleRemoveItem = async (item) => {
    try {
      await removeItemFromShoppingList(item.merchantName, item);
      await fetchShoppingList();
    } catch (error) {
      console.error('Error removing item from shopping list:', error);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, minHeight: '100vh', backgroundColor: '#f9f9ff' }}>
      <Typography variant="h3" textAlign="center" gutterBottom>
        {merchant.name}
      </Typography>
      {merchant.logoUrl && (
        <Box textAlign="center" mb={3}>
          <img
            src={merchant.logoUrl}
            alt={`${merchant.name} logo`}
            style={{ maxHeight: 80 }}
          />
        </Box>
      )}

      {validity.validFrom && validity.validTo && (
        <Typography textAlign="center" color="textSecondary" gutterBottom>
          Valid from: {new Date(validity.validFrom).toLocaleDateString()} to{' '}
          {new Date(validity.validTo).toLocaleDateString()}
        </Typography>
      )}

      <Tabs
        value={selectedCategory}
        onChange={(e, newValue) => setSelectedCategory(newValue)}
        variant="scrollable"
        scrollButtons="auto"
        sx={{ mb: 3 }}
      >
        <Tab label="All" value="All" />
        {Object.keys(groupedItems).map((category) => (
          <Tab key={category} label={category} value={category} />
        ))}
      </Tabs>

      {displayedItems.length === 0 ? (
        <Typography textAlign="center" color="textSecondary">
          No flyer items found for this category.
        </Typography>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 3,
            justifyContent: 'center',
          }}
        >
          {displayedItems.map((item) => (
            <Box
              key={item._id}
              sx={{
                flex: '1 1 calc(25% - 24px)',
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 300,
              }}
            >
              <Card sx={{ height: '100%' }}>
                <CardMedia
                  component="img"
                  image={item.itemImageUrl}
                  alt={item.itemName}
                  sx={{ aspectRatio: '16 / 9' }}
                />
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {item.itemName.slice(0,20)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {renderPriceInfo(item)}
                  </Typography>
                </CardContent>
                <CardActions>
                  {item.isInShoppingList ? (
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => handleRemoveItem(item)}
                    >
                      Remove
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddItem(item)}
                    >
                      Add
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Box>
          ))}
        </Box>
      )}

      <Box mt={4} textAlign="center">
        <Link to="/browse-flyers" style={{ textDecoration: 'none' }}>
          <Button variant="outlined">Back to Flyers</Button>
        </Link>
      </Box>
    </Box>
  );
};

export default FlyerItems;
