import React, { useEffect, useContext } from 'react';
import { ShoppingContext } from './context/ShoppingContext';
import { useNavigate } from 'react-router-dom';
import './css/ShoppingIcon.css';

const ShoppingIcon = () => {
    const { itemCount, shoppingList, fetchShoppingList } = useContext(ShoppingContext);  // Get item count from context
    const navigate = useNavigate();

    // Fetch the shopping list when the component mounts only
    useEffect(() => {
        // Add a conditional to prevent repeated calls
        if (!shoppingList || shoppingList.length === 0) {
            fetchShoppingList();
        }
    }, []); // Empty dependency array ensures it only runs once when component mounts

    const handleClick = () => {
        navigate('/shopping-list');  // Navigate to the shopping list page
    };

    return (
        <div className="shopping-icon" onClick={handleClick} style={{ position: 'relative', cursor: 'pointer' }}>
            <i className="fas fa-shopping-cart" style={{ fontSize: '24px',color:'white' }}></i>
            <div
                className="item-count"
                style={{
                    position: 'absolute',
                    top: '-8px',
                    right: '-8px',
                    backgroundColor: itemCount > 0 ? 'red' : 'lightgray',
                    color: itemCount > 0 ? 'white' : 'black',
                    borderRadius: '50%',
                    padding: '2px 6px',
                    fontSize: '12px',
                }}
            >
                {itemCount}
            </div>
        </div>
    );
};

export default ShoppingIcon;
