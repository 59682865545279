import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from './context/UserContext'; // Import the UserContext
import OAuthButtons from './OAuthButtons'; // Import the new component
import { Box, TextField, Button, Typography, Stack } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import axiosInstance from './BasePath/axiosInstance';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setUser } = useContext(UserContext); // Get setUser from UserContext
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the form from submitting and reloading the page

    try {
      const response = await axiosInstance.post('/auth/login', { email, password });

      if (response?.data?.success) {
        // Store the token in localStorage
        localStorage.setItem('token', response?.data.token);


        const res = await fetch(`/api/auth/me`, {
          method: 'GET', // Change to POST, PUT, DELETE, etc., if needed
          headers: {
            'Content-Type': 'application/json', // Set content type
            Authorization: `Bearer ${response?.data?.token}`, // Add your authorization token
          },
        });
        const userResponse = await res.json();
        console.log(userResponse)
        const userData = {
          fullName: userResponse?.fullName,
          email: userResponse?.email,
          avatar: userResponse?.avatar || 'https://via.placeholder.com/150',
        };

        localStorage.setItem('user', JSON.stringify(userData));
        navigate('/home'); // Redirect to the home page
      } else {
        toast.error(response?.data?.message || 'Login failed.');
        localStorage.clear();
      }
    } catch (error) {
      toast.error('Login failed. Please try again.');
      localStorage.clear();
      console.error('Login failed:', error);
    }
  };


  const handleOAuthLogin = (provider) => {
    // Redirect to backend OAuth route for the provider
    window.location.href = `/api/auth/oauth/${provider}`;
  };

  return (
    <Stack width={'100%'} direction={'row'} justifyContent={'center'} my={4}>
      <Box
        sx={{
          width: 300,
          backgroundColor: '#f9f9ff',
          borderRadius: 2,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          p: 3,
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" sx={{ mb: 1 }}>
          Welcome back!
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Log in to your account
        </Typography>
        <Stack spacing={2}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
              },
            }}
            required
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
              },
            }}
            required
          />
        </Stack>
        <Button
          variant="contained"
          fullWidth
          sx={{
            mt: 3,
            backgroundColor: '#2979ff',
            color: '#fff',
            borderRadius: '10px',
            textTransform: 'none',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#1e5dcf',
            },
          }}
          onClick={handleLogin}
        >
          Login
        </Button>
        <Typography variant="body2" sx={{ mt: 2 }}>
          Don't have an account?{' '}
          <Link to="/signup" style={{ color: '#2979ff', textDecoration: 'none', fontWeight: 'bold' }}>
            Sign up here
          </Link>
        </Typography>
        <OAuthButtons handleOAuthLogin={handleOAuthLogin} />
        <ToastContainer />
      </Box>
    </Stack>
  );
};

export default Login;
