import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LandingPage from './LandingPage';
import CartIcon from './CartIcon';
import { Avatar, Button, Menu, MenuItem, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Home } from '@mui/icons-material';
import ShoppingIcon from './ShoppingIcon';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    variants: [
      {
        props: ({ open }) => open,
        style: {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: 0,
        },
      },
    ],
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function MainBase() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNavigation = (item) => {
    const route = `/${item.toLowerCase().replace(/\s+/g, '-')}`;
    navigate(route);
  };

  const menuItems = [
    { name: 'Home', icon: <Home/> },
    { name: 'Browse Flyers', icon: <StorefrontIcon /> },
    { name: 'Compare Flyers', icon: <CompareArrowsIcon /> },
    { name: 'Meal Planner', icon: <RestaurantMenuIcon /> },
    { name: 'shopping list', icon: <ShoppingCartIcon /> },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex',width:'95vw' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
            <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
            <Stack direction={'row'} alignItems={'center'}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                mr: 2,
              },
              open && { display: 'none' },
            ]}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            GroceryGuru
          </Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} gap={2}>
        <ShoppingIcon/>
        <Avatar sx={{cursor:'pointer',boxShadow:'0px 0px 2px 2px white'}}    onClick={handleClick} src='https://png.pngtree.com/png-clipart/20230604/ourmid/pngtree-3d-avatar-man-like-teacher-writer-png-image_7120495.png'/>
        <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        elevation={2}
        open={open1}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem   color='red' fontWeight={'bold'} onClick={()=>{localStorage.clear(); navigate('/')}}>Logout</MenuItem>
      </Menu> 
          </Stack>
        </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader >
            <Stack direction={'row'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography fontSize={'large'}>LOGO</Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
            </Stack>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            <ListItem
              key={item.name}
              disablePadding
              sx={{
                my:1,
                backgroundColor:
                  location.pathname === `/${item.name.toLowerCase().replace(/\s+/g, '-')}`
                    ? '#e0e0e0'
                    : 'transparent',
              }}
            >
              <ListItemButton onClick={() => handleNavigation(item.name)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
       
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
    <LandingPage/>
      </Main>
    </Box>
  );
}
