import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Card, CardMedia, CardContent, Button, Stack } from '@mui/material';
import CartIcon from './CartIcon';

const LandingPage = () => {

  const { t } = useTranslation();
  const userdata =localStorage.getItem("user");
const user=JSON.parse(userdata);

  const options = [
    {
      title: 'Browse Flyers',
      description: 'Check out the latest deals and offers from your favorite stores.',
      link: '/browse-flyers',
      image: 'https://marketplace.canva.com/EAFZV_gDaw4/1/0/1131w/canva-green-yellow-fresh-and-high-quality-grocery-store-flyer-ikFmpL61jeU.jpg',
    },
    {
      title: 'Compare Flyers',
      description: 'Build your grocery list while getting the best price from your favorite stores.',
      link: '/compare-flyers',
      image: 'https://img.freepik.com/premium-vector/informative-flyer-template-design-grocery-store_606364-763.jpg',
    },
    {
      title: 'Meal Planner',
      description: 'Plan your meals for the week and get organized.',
      link: '/meal-planner',
      image: 'https://type7.in/cdn/shop/files/IMG_9785.jpg?v=1697547164&width=2656',
    },
    {
      title: 'Manage Shopping Lists',
      description: 'Keep track of your shopping lists with ease.',
      link: '/manage-shopping-lists',
      image: 'https://stopfoodwaste.ie/wp-content/uploads/2017/09/shopping-list.jpg',
    },
  ];

  return (
    <Box
      sx={{
       p:2,
        textAlign: 'center',
        width:'100%'
      }}
    >
      {/* Welcome Section */}
      <Typography variant="h3" sx={{ mb: 3, fontWeight: 'bold' }}>
        {t('welcomeGuru')}, {user ? user.fullName : 'User'}!
      </Typography>
      <Typography variant="h6" sx={{ mb: 5, color: '#555' }}>
        Your one-stop shop for planning meals and managing shopping lists.
      </Typography>

      {/* CSS Grid Layout for Options */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          gap: 3,
        }}
      >
        {options.map((option, index) => (
          <Card
            key={index}
            sx={{
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              borderRadius: 2,
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          >
            <CardMedia
              component="img"
              height="200"
              image={option.image}
              alt={option.title}
            />
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                {option.title}
              </Typography>
              <Typography variant="body2" sx={{ color: '#555', mb: 2 }}>
                {option.description}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={option.link}
                sx={{
                  textTransform: 'none',
                  borderRadius: '20px',
                  px: 3,
                }}
              >
                Explore
              </Button>
            
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
   
  );
};

export default LandingPage;
